import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en";
import React from "react";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider } from "react-query";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import queryClient from "./api/queryClient";
import Loading from "./components/Loading";
import { SessionProvider, useSession } from "./context/session.context";
import ErrorPage from "./pages/Error.page";
import BusinessRoot from "./routes/businessRoot";
import BusinessUnit from "./routes/businessUnit";
import BusinessUnits from "./routes/businessUnits";
import Chargebacks from "./routes/chargebacks";
import ConfirmationEmail from "./routes/confirmationEmail";
import Files, { loader as fileLoader } from "./routes/files";
import ForgotPassword from "./routes/forgotPassword";
import Index from "./routes/index";
import Login from "./routes/login";
import RebateCredits from "./routes/rebateCredits";
import ResetPassword from "./routes/resetPassword";
import Root from "./routes/root";
import Sales from "./routes/sales";
import SavedColumnHeaders from './routes/savedColumnHeaders'
import Uploader from "./routes/uploader";
import User from "./routes/user";
import Users from "./routes/users";

const router = createBrowserRouter([
  {
    id: "login",
    path: "/login",
    element: <Login />,
  },
  {
    id: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    id: "reset-password",
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    id: "confirm-email",
    path: "/confirm-email/:token",
    element: <ConfirmationEmail />,
  },
  {
    id: "root",
    path: "/",
    element: (
      <PrivateRoute>
        <Root />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Index /> },
      {
        id: "business",
        path: "/business",
        element: <BusinessRoot />,
        children: [
          {
            path: "files",
            element: <Files />,
            loader: fileLoader,
          },
          {
            path: "uploader",
            element: <Uploader />,
          },
          {
            path: "sales",
            element: <Sales />,
          },
          {
            path: "chargebacks",
            element: <Chargebacks />,
          },
          {
            path: "rebate-credits",
            element: <RebateCredits />,
          },
          {
            path: "saved-column-headers",
            element: <SavedColumnHeaders />
          }
        ],
      },
      {
        id: "admin",
        path: "/admin",
        children: [
          {
            path: "business-units",
            element: <BusinessUnits />,
          },
          {
            path: "business-units/:id",
            element: <BusinessUnit />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "users/:id",
            element: <User />,
          },
        ],
      },
    ],
  },

  // {
  //   path: "/logout",
  //   async action(){
  //     // We signout in a "resource route" that we can hit from a fetcher.Form
  //     await fakeAuthProvider.signout();
  //     return redirect("/");
  //   }
  // }
]);

function PrivateRoute({ children }: { children: React.ReactElement }) {
  const { session, loadedSessionStorage } = useSession();
  if (!loadedSessionStorage) {
    return <Loading />;
  }

  if (!session) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <React.Fragment>
            <RouterProvider
              router={router}
              fallbackElement={<p>Initial loading...</p>}
            />
            <Toaster position="bottom-right" />
          </React.Fragment>
        </SessionProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
