import camelcaseKeys from "camelcase-keys";
import { isArray, isEmpty, isNil } from "lodash";
import toast from "react-hot-toast";
import { redirect } from "react-router-dom";
import snakeCaseKeys from "snakecase-keys";

import { ListResponseData, MetaPagination, Param } from "../types";
import client from "./client";

export async function handleBasicFetch<Type>({
  url,
  params,
}: {
  url: string;
  params?: Param[];
}): Promise<Type> {
  try {
    const searchParams = new URLSearchParams();
    if (params) {
      params.forEach(({ key, value }: Param) => {
        if (!isNil(value)) {
          searchParams.set(key, value);
        }
      });
    }
    const data: Type = await client.get(url, { searchParams }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
    // return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleBasicPost<Type>({
  url,
  input,
}: {
  url: string;
  input: any;
}): Promise<Type> {
  try {
    const data: Type = await client.post(url, { json: input }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleMultiPartFormPost({
  url,
  formData,
}: {
  url: string;
  formData: any;
}): Promise<any> {
  try {
    const response: any = await client.post(url, { body: formData });
    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleDetail<Type>({
  id,
  baseUrl,
}: {
  id?: number | string;
  baseUrl: string;
}): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data = await client.get(`${baseUrl}/${id}`).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleList<Type>({
  page,
  perPage,
  query,
  baseUrl,
  params,
}: {
  page?: number;
  perPage?: number;
  query?: string;
  baseUrl: string;
  params?: Param[];
}): Promise<ListResponseData<Type>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (perPage) {
      searchParams.set("take", perPage.toString());
    }
    if (params && !isEmpty(params)) {
      params.forEach((param) => {
        if (!isNil(param["value"])) {
          searchParams.set(param["key"], param["value"]);
        }
      });
    }

    const response: { data: Type[]; meta: MetaPagination } = await client
      .get(baseUrl, {
        searchParams,
      })
      .json();

    return camelcaseKeys(
      {
        data: response.data || ([] as Type[]),
        meta: response.meta as MetaPagination,
      },
      { deep: true },
    ) as ListResponseData<Type>;
  } catch (error) {
    handleError(error);
    return Promise.reject(error);
  }
}

export async function handleSave<Type>({
  baseUrl,
  input,
}: {
  baseUrl: string;
  input?: any;
}): Promise<Type> {
  try {
    const inputSnaked = snakeCaseKeys(input || {}, {
      deep: true,
    });
    let data: Type;
    if (typeof input.id === "undefined") {
      data = await client.post(baseUrl, { json: inputSnaked }).json();
    } else {
      data = await client
        .put(`${baseUrl}/${input.id}`, { json: inputSnaked })
        .json();
    }
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleDelete<Type>({
  baseUrl,
  id,
}: {
  baseUrl: string;
  id: number;
}): Promise<Type> {
  try {
    const data = await client.delete(`${baseUrl}/${id}`).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleError(error: any) {
  if (error?.code === 401 || error?.data?.statusCode === 401) {
    console.log("handleErrors", error?.data?.statusCode, error?.code);
    return redirect("/login");
  }
  if (error?.data?.message) {
    if (isArray(error?.data?.message)) {
      toast.error(error?.data?.message[0]);
    } else {
      toast.error(error?.data?.message);
    }
  } else {
    toast.error("Internal Server Error.");
  }
  // return Promise.reject(error)
}
