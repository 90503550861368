import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { lighten, styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import React from "react";

import FileApi from "../api/file.api";
import { useBusinessUnit } from "../context/businessUnit.context";
import { useFileUploaderContext } from "../context/fileUploader.context";
import { ColumnHeaders, FileHeader, ValidatedFileRow } from "../types";
import { HttpError } from "../types/http-error";
import { convertToCurrency } from "../utils";

const pageSize = 20;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Rejected": {
    backgroundColor: lighten(theme.palette.error.main, 0.7),
    "&:hover": {
      backgroundColor: lighten(theme.palette.error.main, 0.6),
    },
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.error.main, 0.5),
      "&:hover": {
        backgroundColor: lighten(theme.palette.error.main, 0.4),
      },
    },
  },
}));

export default function ValidateData() {
  const { columnHeaders, validatedFileRows } = useFileUploaderContext();

  if (!validatedFileRows || !columnHeaders) return <></>;

  // const rows: { id: number }[] = [];
  const initialRows: GridRowsProp = validatedFileRows.map(
    (validatedFileRow: ValidatedFileRow, rowIndex: number) => {
      const row: { [key: string | number | symbol]: any } = {
        id: rowIndex + 1,
        valid: validatedFileRow.valid,
        error: validatedFileRow.error,
      };
      Object.keys(validatedFileRow.fileRow).forEach((fileHeaderKey: string) => {
        row[fileHeaderKey] = validatedFileRow.fileRow[fileHeaderKey].value;
      });
      return row;
    },
  );

  console.log('validatedFileRows', validatedFileRows)

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <ValidateDataTable
        columnHeaders={columnHeaders}
        initialRows={initialRows}
      />
    </Box>
  );
}

function ContinueDialog({
  columnHeaders,
  open,
  setOpen,
  rows,
  processRowUpdate,
}: {
  columnHeaders: ColumnHeaders;
  open: boolean;
  setOpen: (arg: boolean) => void;
  rows: readonly GridRowModel[];
  processRowUpdate: (newRow: GridRowModel) => void;
}) {
  const { businessUnit } = useBusinessUnit();
  const {
    fileUtilityType,
    month,
    year,
    setFileUploaderState,
    setSubmittedRows,
  } = useFileUploaderContext();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { mutateAsync } = FileApi.useUploadValidFileData();

  const handleContinue = async () => {
    if (!fileUtilityType) {
      return;
    }
    try {
      setUploading(true);

      // TODO filter unvalid rows before sending
      let validRows = rows.filter((row) =>
        Object.values(row.valid).some((v) => !v) ? false : true,
      );
      validRows = validRows.map((row) => {
        const newRow = row;
        Object.values(columnHeaders).forEach((columnHeader) => {
          if (columnHeader["price"]) {
            newRow[columnHeader.key] = convertToCurrency(row[columnHeader.key]);
          }
        });
        return newRow;
      });
      // need to convert dates to date object
      const res: any = await mutateAsync({
        fileUtilityType,
        rows: validRows,
        businessUnit,
        month,
        year,
      });
      setSubmittedRows && setSubmittedRows(res["data"]);
      setFileUploaderState && setFileUploaderState("complete");
    } catch (error) {
      if (error instanceof HttpError) {
        console.log(error?.data);
        if (!isEmpty(error?.data) && !isEmpty(error?.data?.errors)) {
          // TODO toast error?.data.message
          error.data.errors.forEach((rowError: any) => {
            // const id = rowError.target.id;
            const property = rowError.property;
            const errorMessage = Object.values(rowError.constraints)[0];

            const newTarget = {
              ...rowError.target,
              error: errorMessage,
              valid: {
                ...rowError.target.valid,
                [property]: false,
              },
            };
            processRowUpdate(newTarget);
          });
        }
      }
    } finally {
      setUploading(false);
    }
    // 1. Validate Rows again, validateRow function
    // 2. setValidatedFileRows
    // 3. setFileUploaderState
    // setFileUploaderState && setFileUploaderState("complete")
  };

  const handleClose = () => {
    if (!uploading) {
      setOpen(false);
      return;
    }
    // TODO notify user that loading is still occuring
    console.log("uploading in progress...");
  };

  return (
    <Dialog
      open={open}
      onClick={handleClose}
      aria-labelledby="continue-dialog-title"
      aria-describedby="continue-dialog-description"
    >
      <DialogTitle id="continue-dialog-title">
        Would you like to submit all valid rows?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="continue-dialog-description">
          All other rows will be discarded.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleContinue} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledBox = styled("div")(({ theme }) => ({
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15,0.1)`,
    color: "#750f0f",
  },
}));

// const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.error.main,
//     color: theme.palette.error.contrastText,
//   },
// }));

function ValidateDataTable({
  columnHeaders,
  initialRows,
}: {
  columnHeaders: ColumnHeaders;
  initialRows: GridRowsProp;
}) {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const handleContinue = () => {
    setOpenDialog(true);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows((rows) =>
      rows.map((row) => (row.id === newRow.id ? updatedRow : row)),
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = Object.values(columnHeaders)
    .sort(
      (fileHeaderA: FileHeader, fileHeaderB: FileHeader) =>
        fileHeaderA.sortKey - fileHeaderB.sortKey,
    )
    .map((fileHeader: FileHeader) => ({
      field: fileHeader.key,
      headerName: fileHeader.header,
      sortable: false,
      editable: true,
      type:
        fileHeader.objectType === "custom" ? "string" : fileHeader.objectType,
      preProcessEditCellProps: fileHeader?.preProcessEditCellProps,
      valueGetter: fileHeader?.valueGetter,
      valueSetter: fileHeader?.valueSetter,
      valueOptions: fileHeader?.valueOptions,
    }));

  columns.push({
    field: "valid",
    type: "boolean",
    headerName: "Valid",
    width: 70,
    valueGetter: (value, row) => {
      return Object.values(row.valid).some((v) => !v) ? false : true;
    },
  });

  columns.push({
    field: "error",
    type: "string",
    headerName: "Error Message",
    width: 100,
    valueGetter: (value, row) => {
      return row.error ? row.error : undefined;
    },
  });

  columns.push({
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  });

  console.log("validate data.rows", rows);

  return (
    <>
      <Box
        sx={{
          flexShrink: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <ContinueDialog
          columnHeaders={columnHeaders}
          processRowUpdate={processRowUpdate}
          open={openDialog}
          setOpen={setOpenDialog}
          rows={rows}
        />
        <Button variant="contained" onClick={handleContinue}>
          Continue
        </Button>
      </Box>
      <StyledBox>
        <StyledDataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: pageSize } },
          }}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          pageSizeOptions={[pageSize]}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          getRowClassName={(params) =>
            `super-app-theme--${Object.values(params.row.valid).some((v) => !v) ? "Rejected" : "Accepted"}`
          }
        />
      </StyledBox>
    </>
  );
}
