import { useLoaderData } from "react-router-dom";

// import FileApi from "../api/file.api";
import { File } from "../types";

export async function loader(): Promise<{ files: File[] }> {
  // const files = await FileApi.getFiles();
  const files: File[] = [];
  return { files };
}

export default function Files() {
  const { files } = useLoaderData() as { files: File[] };
  return (
    <div>
      <h1>Files</h1>
      <div>
        {files.length ? (
          <ul>
            {files.map((file: File) => (
              <p key={file.id}>File name: {file.name}</p>
            ))}
          </ul>
        ) : (
          <p>No Files</p>
        )}
      </div>
    </div>
  );
}
