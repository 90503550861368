import {
  FileHeader,
  FileUtilityType,
} from "../types";

import chargebackHeaders from './chargeback-file-headers';
import saleHeaders from './sale-file-headers'
import rebateCreditHeaders from './rebate-credit-file-headers'

export function fileHeadersByFileUtilityType(
  fileUtilityType: FileUtilityType | undefined,
): { [key: string]: FileHeader } | undefined {
  if (fileUtilityType === "direct_sale") {
    return saleHeaders;
  } else if(fileUtilityType === "chargeback") {
    return chargebackHeaders;
  } else if(fileUtilityType === "rebate_credit") {
    return rebateCreditHeaders;
  }
  return undefined;
}