import { Box, Typography } from "@mui/material";
import React from "react";

import FilterListData from "../components/FilterListData";
import RebateCreditTable from "../components/RebateCreditTable";
import { FilterSearchProvider } from "../context/filterSearch.context";

export default function Chargebacks() {
  return (
    <FilterSearchProvider>
      <Box>
        <Box>
          <Typography variant="h2">Rebate Credits</Typography>
        </Box>
        <Box>
          <FilterListData />
        </Box>
        <RebateCreditTable />
      </Box>
    </FilterSearchProvider>
  );
}
