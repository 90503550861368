import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { isArray } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import UserApi from "../api/user.api";
import { UserRole } from "../types";
import { HttpError } from "../types/http-error";

export default function Users() {
  const [openCreate, setOpenCreate] = React.useState(false);
  const { data } = UserApi.useList();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="h2">Users</Typography>
        <Button onClick={() => setOpenCreate(true)}>Create User</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.data?.map((user: any) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Link to={`/admin/users/${user?.id}`}>{user.email}</Link>
                  </TableCell>
                  <TableCell>{user.role}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UserCreateModal
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
      />
    </Box>
  );
}

function UserCreateModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>("");
  const [role, setRole] = React.useState<UserRole>("basic");

  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    React.useState(false);
  const [
    passwordConfirmationErrorMessage,
    setPasswordConfirmationErrorMessage,
  ] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const { mutateAsync, isLoading } = UserApi.useSave();

  const validateInputs = () => {
    let isValid = true;

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else if (
      !/\d/.test(password) ||
      !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
    ) {
      setPasswordError(true);
      setPasswordErrorMessage(
        "Password must have atleast 1 number and 1 special character.",
      );
      isValid = false;
    } else if (!passwordConfirmation && passwordConfirmation !== password) {
      setPasswordConfirmationError(true);
      setPasswordConfirmationErrorMessage("Passwords must match.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  const handleCreate = async () => {
    if (!validateInputs()) {
      return;
    }
    try {
      const res = await mutateAsync({
        name,
        email,
        password,
        passwordConfirmation,
        role,
      });
      handleClose();
    } catch (error) {
      const httpError = error as HttpError;
      if (httpError.code === 400) {
        if (isArray(httpError?.data?.message)) {
          toast.error(httpError?.data?.message[0]);
        } else {
          toast.error(httpError?.data?.message);
        }
      } else {
        toast.error("Internal Server Error");
      }
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Create User</Typography>
        <TextField
          label="Full Name"
          fullWidth
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
        />
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
        />
        <FormControl variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            error={passwordError}
            name="password"
            placeholder="••••••"
            type={showPassword ? "text" : "password"}
            id="password"
            autoFocus
            required
            fullWidth
            label="Password"
            color={passwordError ? "error" : "primary"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(event.target.value)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {passwordErrorMessage && (
            <FormHelperText id="password-error-text">
              {passwordErrorMessage}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="passwordConfirmation">
            Password Confirmation
          </InputLabel>
          <OutlinedInput
            error={passwordConfirmationError}
            name="passwordConfirmation"
            placeholder="••••••"
            type={showPassword ? "text" : "password"}
            id="passwordConfirmation"
            autoFocus
            required
            fullWidth
            label="Password Confirmation"
            color={passwordConfirmationError ? "error" : "primary"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPasswordConfirmation(event.target.value)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {passwordConfirmationErrorMessage && (
            <FormHelperText id="password-error-text">
              {passwordConfirmationErrorMessage}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="user-role-select-label">Role</InputLabel>
          <Select
            labelId="user-role-select-label"
            id="user-role-select"
            value={role}
            label="Role"
            onChange={(event: SelectChangeEvent) => {
              setRole(event.target.value as UserRole);
            }}
          >
            <MenuItem value={"basic"}>Basic</MenuItem>
            <MenuItem value={"admin"}>Admin</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleCreate} disabled={isLoading}>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
