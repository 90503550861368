import { Box, Typography } from "@mui/material";
import React from "react";

import ChargebackTable from "../components/ChargebackTable";
import FilterListData from "../components/FilterListData";
import { FilterSearchProvider } from "../context/filterSearch.context";

export default function Chargebacks() {
  return (
    <FilterSearchProvider>
      <Box>
        <Box>
          <Typography variant="h2">ChargeBacks</Typography>
        </Box>
        <Box>
          <FilterListData />
        </Box>
        <ChargebackTable />
      </Box>
    </FilterSearchProvider>
  );
}
