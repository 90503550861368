import dayjs from "dayjs";
import lodash from "lodash";

import {
  FileHeader,
  ValidatedFileRow,
  ValidationFunc,
} from "../types";

export function excelHeaderName(i: number) {
  const secondaryCharNum = i % 26;
  const primaryCharNum = i / 26;
  if (primaryCharNum >= 1) {
    const primaryChar = String.fromCharCode(64 + Math.floor(primaryCharNum));
    const secondaryChar = String.fromCharCode(
      65 + Math.floor(secondaryCharNum),
    );
    return `${primaryChar}${secondaryChar}`;
  }

  return String.fromCharCode(65 + secondaryCharNum);
}

export function validateRow({
  fileRow,
}: {
  fileRow: {
    [key: number]: {
      fileHeader: FileHeader;
      value: any;
    };
  };
}): ValidatedFileRow {
  const rowValues: { [key: string]: boolean } = {};
  Object.values(fileRow).forEach((cell) => {
    rowValues[cell.fileHeader.key] = cell.value;
  });

  const valid: { [key: string]: boolean } = {};
  Object.keys(fileRow).forEach(
    (columnKey: string) => (valid[columnKey] = true),
  );
  let error = undefined;
  Object.values(fileRow).forEach((cell) => {
    const fileHeader: FileHeader = cell["fileHeader"];
    const value = cell["value"];
    const passedValidations = lodash.isEmpty(fileHeader.validationFuncs)
      ? true
      : !fileHeader.validationFuncs.some(
          (validationFunc) => !validationFunc(value, rowValues),
        );

    valid[fileHeader.key] = passedValidations;
    // if(fileHeader.key === 'cbAmount'){
    //   debugger;
    // }
    if (!passedValidations) {
      error = `Invalid ${fileHeader.header}`;
    }
  });
  return { valid, error, fileRow };
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const convertPriceToCents = (value?: string | number): number => {
  if (!value) {
    return 0;
  }
  if (lodash.isNumber(value)) {
    return value * 100;
  }
  const striped = value.replace(/[^\d.]/g, "");
  const strToNum = parseFloat(striped);
  return strToNum * 100;
};

export const convertToDate = (value?: string | Date): Date | undefined => {
  if (!value || !dayjs(value).isValid()) {
    return undefined;
  }
  return dayjs(value).toDate();
};

export const isBlank = (value: any) => {
  if (lodash.isNil(value) || (lodash.isString(value) && value.length === 0)) {
    return true;
  }
  return false;
};

export const convertNumberToCurrency = (value: number) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const convertStringToCurrency = (value: string) => {
  const striped = value.replace(/[^\d.]/g, "");
  const strToNum = parseFloat(striped);
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(strToNum);
};

export const convertToCurrency = (value: string | number) => {
  if (lodash.isNumber(value)) {
    return convertNumberToCurrency(value);
  } else if (isBlank(value) || !lodash.isString(value)) {
    return "$0.00";
  }
  return convertStringToCurrency(value);
};

export const currencyValueGetter = (value: string, row: any) => {
  if (lodash.isNumber(value)) {
    return convertNumberToCurrency(value);
  } else if (isBlank(value) || !lodash.isString(value)) {
    return "";
  }
  return convertStringToCurrency(value);
};

export const isRequired = (value: any, row: any): boolean => {
  return isBlank(value) ? false : true;
};

export const isDate = (value: any, row: any): boolean => {
  return dayjs(value).isValid();
};

export const isNumber = (value: any): boolean => {
  try {
    return lodash.isNumber(lodash.toNumber(value));
  } catch (error) {
    return false;
  }
};

export const isPrice = (value: any, row: any): boolean => {
  if (!isBlank(value) && (lodash.isString(value) || isNumber(value))) {
    return true;
  }
  return false;
};

// export const isEnum = (value: any, options: []): boolean => {}

export const isValidOtherColumns =
  (otherColumnKeys: Array<string>, required?: boolean) =>
  (value: any, row: any): boolean => {
    const defaultRequired = required === false ? false : true;
    if (
      !isBlank(value) ||
      (isBlank(value) && otherColumnKeys.some((key) => !isBlank(row[key]))) ||
      !defaultRequired
    ) {
      return true;
    }
    return false;
  };

export const convertPrice = (value: any): any => {
  if (!isBlank(value) && lodash.isString(value)) {
    return convertStringToCurrency(value);
  }
  return "";
};

export const valueSetter =
  (
    fileHeaderKey: string,
    validationFuncs: Array<ValidationFunc>,
    manipulateValue?: (arg: any) => any,
  ) =>
  (value: any, row: any) => {
    let newValue = value;
    if (manipulateValue) {
      newValue = manipulateValue(value);
    }
    const valid = row["valid"];
    valid[fileHeaderKey] = lodash.isEmpty(validationFuncs)
      ? true
      : !validationFuncs.some(
          (validationFunc) => !validationFunc(newValue, row),
        );
    let error = row["error"];
    if (!valid[fileHeaderKey]) {
      error = `Invalid ${fileHeaderKey}`;
    } else {
      error = undefined;
    }
    return { ...row, error, valid, [fileHeaderKey]: newValue };
  };


