import { Box, Typography } from "@mui/material";
import React from "react";

import SaleTable from "../components/SaleTable";
import FilterListData from "../components/FilterListData";
import { FilterSearchProvider } from "../context/filterSearch.context";

export default function Sales() {
  return (
    <FilterSearchProvider>
      <Box>
        <Box>
          <Typography variant="h2">Sales</Typography>
        </Box>
        <Box>
          <FilterListData />
        </Box>
        <SaleTable />
      </Box>
    </FilterSearchProvider>
  );
}
