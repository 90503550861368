import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import toast from "react-hot-toast";

import FileApi from "../api/file.api";
import { FileUtilityType, SavedHeaderSchema } from "../types";
import { useBusinessUnit } from '../context/businessUnit.context'

export default function SaveHeaderSchemaDialog({
  open,
  fileUtilityType,
  handleCancel,
  handleContinue,
  selectedHeaders,
  savedHeaderSchema
}: {
  open: boolean;
  fileUtilityType: FileUtilityType;
  handleCancel: () => void;
  handleContinue: () => void;
  selectedHeaders: { [key: number]: string };
  savedHeaderSchema?: SavedHeaderSchema;
}) {
  const [nameOfSchema, setNameOfSchema] = React.useState<string>("");

  const { businessUnit } = useBusinessUnit()

  const { mutateAsync: createSavedHeaderSchema } = FileApi.useCreateSavedHeaderSchemas({
    businessUnitId: businessUnit?.id,
    columnHeaders: selectedHeaders,
    fileUtilityType,
    name: nameOfSchema,
  });

  const { mutateAsync: updateSavedHeaderSchema } = FileApi.useUpdateSavedHeaderSchemas({
    businessUnitId: businessUnit?.id,
    columnHeaders: selectedHeaders,
    savedHeaderSchemaId: savedHeaderSchema?.id,
    name: nameOfSchema,
  })

  const handleSave = async () => {
    try {
      if(savedHeaderSchema?.id){
        await updateSavedHeaderSchema();
      } else {
        await createSavedHeaderSchema();
      }
      toast.success("Saved mappings");
      handleContinue();
    } catch (error) {
      console.error(error);
      // TODO show error
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-describedby="alert-save-header-schema-dialog-description"
    >
      <DialogTitle>{"Save Column Header Mapping"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-save-header-schema-dialog-description">
          Would you like to save the following column header mapping?
        </DialogContentText>
        <TextField
          id="name-of-schema-input"
          label="Name of mapping"
          variant="standard"
          value={nameOfSchema}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNameOfSchema(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Disagree</Button>
        <Button onClick={handleSave}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}
