import { Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

import { useFileUploaderContext } from "../context/fileUploader.context";

export default function SelectMonthAndYear() {
  const { setFileUploaderState, month, setMonth, year, setYear } =
    useFileUploaderContext();
  const [datePicker, setDatePicker] = React.useState<Dayjs | null>(
    month && year ? dayjs().year(year).month(month) : null,
  ); // [1

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >

        <DatePicker
          label={"Month & Year"}
          views={["month", "year"]}
          value={datePicker}
          onChange={(newValue) => {
            if (newValue) {
              setDatePicker(newValue);
            } else {
              setDatePicker(null);
            }
          }}
        />
        {datePicker && (
          <Button
            variant={"contained"}
            onClick={() => {
              setMonth && setMonth(datePicker.month() + 1);
              setYear && setYear(datePicker.year());
              setFileUploaderState && setFileUploaderState("upload_screen");
            }}
            disabled={!datePicker}
          >
            Continue
          </Button>
        )}
      </Box>
    </>
  );
}
