import {
  Box,
  Paper,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from '@mui/icons-material/Delete';

import React from 'react'

import FileApi from '../api/file.api'
import { FileUtilityType, SavedHeaderSchema } from "../types";
import TablePaginationActions from "../components/TablePaginationActions";
import { useBusinessUnit } from '../context/businessUnit.context'

export default function SavedColumnHeaders(){
  const [query, setQuery] = React.useState<string>("")
  const [page, setPage] = React.useState<number>(0)
  const [perPage, setPerPage] = React.useState<number>(10)
  const [fileUtilityType, setFileUtilityType] = React.useState<FileUtilityType | undefined>(undefined)

  const { businessUnit } = useBusinessUnit()

  const { data } = FileApi.useFetchSavedHeaderSchemasPaginated({
    businessUnitId: businessUnit?.id,
    query,
    fileUtilityType,
    perPage,
    page
  })

  const { mutateAsync: deleteSavedHeaderSchema } = FileApi.useDeleteSavedHeaderSchemas()

  const handleFileUtilityTypeChange = (event: SelectChangeEvent) => {
    const newFileUtilityType = event.target.value;
    if (newFileUtilityType) {
      setFileUtilityType &&
        setFileUtilityType(newFileUtilityType as FileUtilityType);
    }
  };

  const handleDelete = (id: number) => async () => {
    try {
      await deleteSavedHeaderSchema(id)
    } catch(error){
      console.error(error)
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box>
          <Typography variant="h2">Saved Column Headers</Typography>
        </Box>

        <Box sx={{ maxWidth: "20rem" }}>
          <FormControl fullWidth>
            <InputLabel id="file-utility-type-select-label">File Type</InputLabel>
            <Select
              labelId="file-utility-type-select-label"
              id="file-utility-type-select"
              value={fileUtilityType}
              label="File Type"
              onChange={handleFileUtilityTypeChange}
            >
              <MenuItem value={"sales"}>Sales</MenuItem>
              <MenuItem value={"chargebacks"}>Chargebacks</MenuItem>
              <MenuItem value={"rebate_credits"}>Rebate Credits</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {fileUtilityType && (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.data.map((row: SavedHeaderSchema) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={handleDelete(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {data && data.meta && perPage && (
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={5}
                      count={data.meta.count}
                      rowsPerPage={perPage}
                      page={data.meta.current}
                      slotProps={{
                        select: {
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        },
                      }}
                      onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setPerPage(parseInt(event.target.value, 10));
                        setPage(0)
                      }}
                      onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                        setPage(newPage)
                      }}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  )
}