import dayjs from "dayjs";

import {
  currencyValueGetter,
  convertPrice,
  isDate,
  isNumber,
  isPrice,
  isValidOtherColumns,
  isRequired,
  valueSetter,
} from './index';
import {FileHeader} from "../types";

const sortKey = (key: string) =>
  [
    "invoiceDate",
    "invoiceNumber",
    "poNumber",
    "poDate",
    "soldToName",
    "soldToAddressLine1",
    "soldToAddressLine2",
    "soldToAddressLine3",
    "soldToCity",
    "soldToState",
    "soldToPostalCode",
    "soldToCountry",
    "shipToName",
    "shipToAddressLine1",
    "shipToAddressLine2",
    "shipToAddressLine3",
    "shipToCity",
    "shipToState",
    "shipToPostalCode",
    "shipToCountry",
    "directClassOfTrade",
    "directClassOfTradeStatus",
    "facilityId",
    "facilityHin",
    "facilityDea",
    "contractId",
    "contractIdDescription",
    "ndc",
    "productDescription",
    "quantity",
    "unitPrice",
    "invoiceAmount",
    "monthlyCpp",
    "quarterlyCpp",
    "transactionType",
  ].indexOf(key);


const headers:  { [key: string]: FileHeader } = {
  invoiceDate: {
    key: "invoiceDate",
    header: "Invoice Date",
    required: true,
    objectType: "date",
    sortKey: sortKey("invoiceDate"),
    valueGetter: (value: any, row: any) => {
      return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
    },
    valueSetter: valueSetter("invoiceDate", [isRequired, isDate]),
    validationFuncs: [isRequired, isDate],
  },
  invoiceNumber: {
    key: "invoiceNumber",
    header: "Invoice Number",
    objectType: "number",
    required: false,
    sortKey: sortKey("invoiceNumber"),
    valueSetter: valueSetter("invoiceNumber", [isNumber]),
    validationFuncs: [isNumber],
  },
  poNumber: {
    key: "poNumber",
    header: "P.O. Number",
    objectType: "number",
    required: false,
    sortKey: sortKey("poNumber"),
    valueSetter: valueSetter("poNumber", [isNumber]),
    validationFuncs: [isNumber],
  },
  poDate: {
    key: "poDate",
    header: "P.O. Date",
    objectType: "date",
    required: false,
    sortKey: sortKey("poDate"),
    valueGetter: (value: any, row: any) => {
      return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
    },
    valueSetter: valueSetter("poNumber", [isDate]),
    validationFuncs: [isDate],
  },
  soldToName: {
    key: "soldToName",
    header: "Sold to Name",
    objectType: "string",
    sortKey: sortKey("soldToName"),
    required: false,
    valueSetter: valueSetter("soldToName", [
      isValidOtherColumns(["shipToName"]),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToName"])],
  },
  soldToAddressLine1: {
    key: "soldToAddressLine1",
    header: "Sold to Address Line 1",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToAddressLine1"),
    valueSetter: valueSetter("soldToAddressLine1", [
      isValidOtherColumns(["shipToAddressLine1"]),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToAddressLine1"])],
  },
  soldToAddressLine2: {
    key: "soldToAddressLine2",
    header: "Sold to Address Line 2",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToAddressLine2"),
    valueSetter: valueSetter("soldToAddressLine2", [
      isValidOtherColumns(["shipToAddressLine2"], false),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToAddressLine2"], false)],
  },
  soldToAddressLine3: {
    key: "soldToAddressLine3",
    header: "Sold to Address Line 3",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToAddressLine3"),
    valueSetter: valueSetter("soldToAddressLine3", [
      isValidOtherColumns(["shipToAddressLine3"], false),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToAddressLine3"], false)],
  },
  soldToCity: {
    key: "soldToCity",
    header: "Sold to City",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToCity"),
    valueSetter: valueSetter("soldToCity", [
      isValidOtherColumns(["shipToCity"]),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToCity"])],
  },
  soldToState: {
    key: "soldToState",
    header: "Sold to State",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToState"),
    valueSetter: valueSetter("soldToState", [
      isValidOtherColumns(["shipToState"]),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToState"])],
  },
  soldToPostalCode: {
    key: "soldToPostalCode",
    header: "Sold to Postal Code",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToPostalCode"),
    valueSetter: valueSetter("soldToPostalCode", [
      isValidOtherColumns(["shipToPostalCode"]),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToPostalCode"])],
  },
  soldToCountry: {
    key: "soldToCountry",
    header: "Sold to Country",
    objectType: "string",
    required: false,
    sortKey: sortKey("soldToCountry"),
    valueSetter: valueSetter("soldToCountry", [
      isValidOtherColumns(["shipToCountry"], false),
    ]),
    validationFuncs: [isValidOtherColumns(["shipToCountry"], false)],
  },
  shipToName: {
    key: "shipToName",
    header: "Ship to Name",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToName"),
    valueSetter: valueSetter("shipToName", [
      isValidOtherColumns(["soldToName"]),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToName"])],
  },
  shipToAddressLine1: {
    key: "shipToAddressLine1",
    header: "Ship to Address Line 1",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToAddressLine1"),
    valueSetter: valueSetter("shipToAddressLine1", [
      isValidOtherColumns(["soldToAddressLine1"]),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToAddressLine1"])],
  },
  shipToAddressLine2: {
    key: "shipToAddressLine2",
    header: "Ship to Address Line 2",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToAddressLine2"),
    valueSetter: valueSetter("shipToAddressLine2", [
      isValidOtherColumns(["soldToAddressLine2"], false),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToAddressLine2"], false)],
  },
  shipToAddressLine3: {
    key: "shipToAddressLine3",
    header: "Ship to Address Line 3",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToAddressLine3"),
    valueSetter: valueSetter("shipToAddressLine3", [
      isValidOtherColumns(["soldToAddressLine3"], false),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToAddressLine3"], false)],
  },
  shipToCity: {
    key: "shipToCity",
    header: "Ship to City",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToCity"),
    valueSetter: valueSetter("shipToCity", [
      isValidOtherColumns(["soldToCity"]),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToCity"])],
  },
  shipToState: {
    key: "shipToState",
    header: "Ship to State",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToState"),
    valueSetter: valueSetter("shipToState", [
      isValidOtherColumns(["soldToState"]),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToState"])],
  },
  shipToPostalCode: {
    key: "shipToPostalCode",
    header: "Ship to Postal Code",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToPostalCode"),
    valueSetter: valueSetter("shipToPostalCode", [
      isValidOtherColumns(["soldToPostalCode"]),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToPostalCode"])],
  },
  shipToCountry: {
    key: "shipToCountry",
    header: "Ship to Country",
    objectType: "string",
    required: false,
    sortKey: sortKey("shipToCountry"),
    valueSetter: valueSetter("shipToCountry", [
      isValidOtherColumns(["soldToCountry"], false),
    ]),
    validationFuncs: [isValidOtherColumns(["soldToCountry"], false)],
  },
  directClassOfTrade: {
    key: "directClassOfTrade",
    header: "Direct Class of Trade",
    objectType: "string",
    required: true,
    sortKey: sortKey("directClassOfTrade"),
    valueSetter: valueSetter("directClassOfTrade", [isRequired]),
    validationFuncs: [isRequired],
  },
  directClassOfTradeStatus: {
    key: "directClassOfTradeStatus",
    header: "Direct Class of Trade Status",
    objectType: "string",
    required: true,
    sortKey: sortKey("directClassOfTradeStatus"),
    valueSetter: valueSetter("directClassOfTradeStatus", [isRequired]),
    validationFuncs: [isRequired],
  },
  facilityId: {
    key: "facilityId",
    header: "Facility ID",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityId"),
    valueSetter: valueSetter("facilityId", [
      isValidOtherColumns(["facilityDea", "facilityHin"]),
    ]),
    validationFuncs: [isValidOtherColumns(["facilityDea", "facilityHin"])],
  },
  facilityHin: {
    key: "facilityHin",
    header: "Facility HIN",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityHin"),
    valueSetter: valueSetter("facilityHin", [
      isValidOtherColumns(["facilityDea", "facilityId"]),
    ]),
    validationFuncs: [isValidOtherColumns(["facilityDea", "facilityId"])],
  },
  facilityDea: {
    key: "facilityDea",
    header: "Facility DEA",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityDea"),
    valueSetter: valueSetter("facilityDea", [
      isValidOtherColumns(["facilityHin", "facilityId"]),
    ]),
    validationFuncs: [isValidOtherColumns(["facilityHin", "facilityId"])],
  },
  contractId: {
    key: "contractId",
    header: "Contract ID",
    objectType: "string",
    required: false,
    sortKey: sortKey("contractId"),
    valueSetter: valueSetter("contractId", []),
    validationFuncs: [],
  },
  contractIdDescription: {
    key: "contractIdDescription",
    header: "Contract ID Description",
    objectType: "string",
    required: false,
    sortKey: sortKey("contractIdDescription"),
    valueSetter: valueSetter("contractIdDescription", []),
    validationFuncs: [],
  },
  ndc: {
    key: "ndc",
    header: "NDC",
    objectType: "string",
    required: true,
    sortKey: sortKey("ndc"),
    valueSetter: valueSetter("ndc", [isRequired]),
    validationFuncs: [isRequired],
  },
  productDescription: {
    key: "productDescription",
    header: "Product Description",
    objectType: "string",
    required: true,
    sortKey: sortKey("productDescription"),
    valueSetter: valueSetter("productDescription", [isRequired]),
    validationFuncs: [isRequired],
  },
  quantity: {
    key: "quantity",
    header: "Quantity",
    objectType: "number",
    required: true,
    sortKey: sortKey("quantity"),
    valueSetter: valueSetter("quantity", [isRequired, isNumber]),
    validationFuncs: [isRequired, isNumber],
  },
  unitPrice: {
    key: "unitPrice",
    header: "Unit Price",
    objectType: "string",
    required: true,
    sortKey: sortKey("unitPrice"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter(
      "unitPrice",
      [isRequired, isPrice],
      convertPrice,
    ),
    validationFuncs: [isRequired, isPrice],
  },
  invoiceAmount: {
    key: "invoiceAmount",
    header: "Invoice Amount",
    objectType: "string",
    required: true,
    sortKey: sortKey("invoiceAmount"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter(
      "invoiceAmount",
      [isRequired, isPrice],
      convertPrice,
    ),
    validationFuncs: [isRequired, isPrice],
  },
  monthlyCpp: {
    key: "monthlyCpp",
    header: "Monthly CPP",
    objectType: "string",
    required: true,
    sortKey: sortKey("monthlyCpp"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter("monthlyCpp", [isPrice], convertPrice),
    validationFuncs: [isPrice],
  },
  quarterlyCpp: {
    key: "quarterlyCpp",
    header: "Quarterly CPP",
    objectType: "string",
    required: true,
    sortKey: sortKey("quarterlyCpp"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter("quarterlyCpp", [isPrice], convertPrice),
    validationFuncs: [isPrice],
  },
  transactionType: {
    key: "transactionType",
    header: "Transaction Type",
    objectType: "singleSelect",
    valueOptions: [
      "Sales",
      "Chargeback",
      "Shortage",
      "Overage",
      "Pricing Adjustment",
      "Shelf Stock Adjustment",
      "Return",
      "Not AMP Eligible Labeler/Product",
    ],
    required: false,
    sortKey: sortKey("transactionType"),
    valueSetter: valueSetter("transactionType", []),
    validationFuncs: [],
  },
};

export default headers;