import { handleBasicPost } from "./handlers";

const methods = {
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ token: string }> => {
    return handleBasicPost({
      url: "login",
      input: { email, password },
    });
  },
  confirmEmail: (token: string): Promise<void> => {
    return handleBasicPost({
      url: "authentication/confirm-email",
      input: { token },
    });
  },
  sendForgotPasswordLink: (email: string): Promise<void> => {
    return handleBasicPost({
      url: "authentication/forgot-password",
      input: { email },
    });
  },
  resetPassword: ({
    token,
    password,
    passwordConfirmation,
  }: {
    token: string;
    password: string;
    passwordConfirmation: string;
  }): Promise<{ token: string }> => {
    return handleBasicPost({
      url: "authentication/reset-password",
      input: { token, password, passwordConfirmation },
    });
  },
};

export default methods;