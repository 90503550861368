import { useQuery } from "react-query";

import { ListResponseData, RebateCredit } from "../types";
import { handleError, handleList } from "./handlers";

const methods = {
  useList: ({
    query,
    page,
    perPage,
    businessUnitId,
    month,
    year,
  }: {
    page?: number;
    perPage?: number;
    query?: string;
    businessUnitId?: number;
    month?: number;
    year?: number;
  }) => {
    const params = businessUnitId
      ? [{ key: "business_unit_id", value: businessUnitId }]
      : [];
    if (month) {
      params.push({ key: "month", value: month });
    }
    if (year) {
      params.push({ key: "year", value: year });
    }
    return useQuery<ListResponseData<RebateCredit>>({
      retry: 0,
      queryKey: ["rebateCredits", businessUnitId, page, perPage, query, month, year],
      queryFn: () =>
        handleList({
          page,
          perPage,
          query: query || "",
          params,
          baseUrl: "rebate-credits",
        }),
      onError: handleError,
    });
  },
};

export default methods;
