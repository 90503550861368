import { Box, Button, TextField } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";

import BusinessUnitApi from "../../api/businessUnit.api";
import { BusinessUnit } from "../../types";

export default function BusinessUnitForm({
  businessUnit,
}: {
  businessUnit: BusinessUnit;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(businessUnit.name);

  const { mutateAsync } = BusinessUnitApi.useSave();

  const handleSave = async () => {
    try {
      setLoading(true);
      await mutateAsync({ id: businessUnit.id, name });
      toast.success("Successfully saved.");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <TextField
          fullWidth={true}
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box>
        <Button disabled={loading} variant="contained" fullWidth={true} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
