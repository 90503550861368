import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import BusinessUnitApi from "../api/businessUnit.api";
import { BusinessUnit } from "../types";

export default function BusinessUnits() {
  const [openCreate, setOpenCreate] = React.useState(false);
  const { data } = BusinessUnitApi.useList({ query: undefined });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="h2">Business Units</Typography>
        <Button onClick={() => setOpenCreate(true)}>
          Create Business Unit
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.data?.map((businessUnit: BusinessUnit) => (
                <TableRow key={businessUnit.id}>
                  <TableCell>
                    <Link to={`/admin/business-units/${businessUnit?.id}`}>
                      {businessUnit.name}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BusinessCreateModal
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
      />
    </Box>
  );
}

function BusinessCreateModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");

  const { mutateAsync } = BusinessUnitApi.useSave();

  const handleCreate = async () => {
    try {
      setLoading(true);
      const res = await mutateAsync({
        name,
      });
      handleClose();
      navigate(`/admin/business-units/${res.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Create Business Unit</Typography>
        <TextField
          label="Business Name"
          fullWidth
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
        />
        <Button variant="contained" onClick={handleCreate} disabled={loading}>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
