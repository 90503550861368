import { jwtDecode } from "jwt-decode";
import ky from "ky";
import { isEmpty, isNil } from "lodash";
import toast from "react-hot-toast";

import { Session } from "../types";
import { HttpError } from "../types/http-error";

const DOMAIN = process.env.REACT_APP_DOMAIN || "";

const client = ky.extend({
  retry: 0,
  prefixUrl: process.env.REACT_APP_API_ENDPOINT,
  hooks: {
    beforeRequest: [
      (request) => {
        const jwtToken = window.localStorage.getItem(
          process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        );
        request.headers.set("Access-Control-Allow-Origin", DOMAIN);
        console.log("beforeRequest", jwtToken, DOMAIN);
        if (!(isNil(jwtToken) || isEmpty(jwtToken))) {
          request.headers.set("Access-Control-Allow-Credentials", "true");
          const sessionData = jwtDecode<Session>(jwtToken);
          const expDate = sessionData["exp"];
          const currentTime = Date.now() / 1000;
          console.log(
            "if-statement",
            expDate,
            currentTime,
            expDate > currentTime,
          );
          if (jwtToken && expDate && expDate > currentTime) {
            request.headers.set("authorization", `Bearer ${jwtToken}`);
          } else {
            toast.error("Automatically signed out");
            window.location.reload();
          }
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response && response.status === 401) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status === 413) {
          const errorJson = await response.json();
          throw new HttpError(response.status, errorJson["errors"]);
        } else if (response && response.status >= 400) {
          const errorJson = await response.json();
          throw new HttpError(response.status, "", errorJson);
        }
      },
    ],
  },
});

export default client;
