import { Dayjs } from "dayjs";
import React from "react";

interface IFilterSearchContext {
  rowsPerPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  toggleFilter: boolean;
  setToggleFilter: React.Dispatch<React.SetStateAction<boolean>>;
  datePickerMonth: Dayjs | null;
  setDatePickerMonth: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  datePickerYear: Dayjs | null;
  setDatePickerYear: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const rowsPerPage = 10;

const FilterSearchContext = React.createContext<Partial<IFilterSearchContext>>({
  page: 0,
  setPage: undefined,
  rowsPerPage,
  perPage: 10,
  setPerPage: undefined,
  query: "",
  setQuery: undefined,
  toggleFilter: false,
  setToggleFilter: undefined,
  datePickerMonth: null,
  setDatePickerMonth: undefined,
  datePickerYear: null,
  setDatePickerYear: undefined,
  handleChangePage: undefined,
  handleChangeRowsPerPage: undefined,
});

export function FilterSearchProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [query, setQuery] = React.useState("");
  const [toggleFilter, setToggleFilter] = React.useState(false);
  const [datePickerMonth, setDatePickerMonth] = React.useState<Dayjs | null>(
    null,
  );
  const [datePickerYear, setDatePickerYear] = React.useState<Dayjs | null>(
    null,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <FilterSearchContext.Provider
      value={{
        page,
        setPage,
        perPage,
        setPerPage,
        query,
        setQuery,
        toggleFilter,
        setToggleFilter,
        datePickerMonth,
        setDatePickerMonth,
        datePickerYear,
        setDatePickerYear,
        handleChangePage,
        handleChangeRowsPerPage,
        rowsPerPage,
      }}
    >
      {children}
    </FilterSearchContext.Provider>
  );
}

export function useFilterSearchContext() {
  return React.useContext(FilterSearchContext);
}
