import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BusinessIcon from "@mui/icons-material/Business";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MenuIcon from "@mui/icons-material/Menu";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SellIcon from "@mui/icons-material/Sell";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { ThemeProvider, styled } from "@mui/material/styles";
import React from "react";
import { Link, Outlet, useLocation, useMatches } from "react-router-dom";

import Copyright from "../components/Copyright";
import { useBusinessUnit } from "../context/businessUnit.context";
import { useSession } from "../context/session.context";
import theme from "../theme";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Root() {
  const { businessUnit } = useBusinessUnit();
  const { logout } = useSession();
  const [open, setOpen] = React.useState<boolean>(false);

  const location = useLocation();
  const matches = useMatches();

  const isBusinessRoute = matches.find((obj) => obj.id === "business");
  const isAdminRoute = matches.find((obj) => obj.id === "admin");
  const isRoot = location?.pathname === "/";

  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {businessUnit && !isAdminRoute && !isRoot
                ? businessUnit?.name
                : "GP SYSTEM"}
            </Typography>
            {/*<IconButton color="inherit">*/}
            {/*  <Badge badgeContent={4} color="secondary">*/}
            {/*    <NotificationsIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {isBusinessRoute && (
              <>
                <Link to="business/uploader">
                  <ListItemButton>
                    <ListItemIcon>
                      <FileUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary="File Upload" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
                <Link to="business/sales">
                  <ListItemButton>
                    <ListItemIcon>
                      <SellIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sales" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
                <Link to="business/chargebacks">
                  <ListItemButton>
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Chargebacks" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
                <Link to="business/rebate-credits">
                  <ListItemButton>
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rebate Credits" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
                <Link to="business/saved-column-headers">
                  <ListItemButton>
                    <ListItemIcon>
                      <ViewColumnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Saved Column Headers" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
              </>
            )}
            {isAdminRoute ? (
              <>
                <Link to="/">
                  <ListItemButton>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
                <Link to="admin/business-units">
                  <ListItemButton>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Business Units" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
                <Link to="admin/users">
                  <ListItemButton>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
              </>
            ) : (
              <>
                <Link to="admin">
                  <ListItemButton>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admin Panel" />
                  </ListItemButton>
                </Link>
                <Divider sx={{ my: 1 }} />
              </>
            )}
            <ListItemButton>
              <ListItemIcon onClick={logout}>
                <ExitToAppIcon />
              </ListItemIcon>
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
