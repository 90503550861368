import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isArray } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import SessionApi from "../api/session.api";
import welcomeBgImg from "../assets/welcome-bg.jpg";
import { useSession } from "../context/session.context";
import { HttpError } from "../types/http-error";

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "auto",
  backgroundImage: `url(${welcomeBgImg})`,
  backgroundSize: "cover",
  backgroundPosition: "left",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    height: "100dvh",
  },
}));

const MainCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

export default function ResetPassword() {
  const navigate = useNavigate();
  const { saveSessionData } = useSession();
  const { token } = useParams();

  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");

  const [passwordConfirmationError, setPasswordConfirmationError] =
    React.useState(false);
  const [
    passwordConfirmationErrorMessage,
    setPasswordConfirmationErrorMessage,
  ] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }
    if (!token) {
      return toast.error("Invalid token");
    }
    setLoading(true);

    const data = new FormData(event.currentTarget);

    try {
      const password = data.get("password") as string;
      const passwordConfirmation = data.get("password-confirmation") as string;
      const result = await SessionApi.resetPassword({
        token,
        password,
        passwordConfirmation,
      });
      saveSessionData && saveSessionData(result?.token);
      navigate("/");
    } catch (error) {
      const httpError = error as HttpError;
      if (httpError.code === 400) {
        if (isArray(httpError?.data?.message)) {
          toast.error(httpError?.data?.message[0]);
        } else {
          toast.error(httpError?.data?.message);
        }
      } else {
        toast.error("Internal Server Error");
      }
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    const password = document.getElementById("password") as HTMLInputElement;
    const passwordConfirmation = document.getElementById(
      "password-confirmation",
    ) as HTMLInputElement;

    let isValid = true;

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else if (
      !/\d/.test(password.value) ||
      !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password.value)
    ) {
      setPasswordError(true);
      setPasswordErrorMessage(
        "Password must have atleast 1 number and 1 special character.",
      );
      isValid = false;
    } else if (
      !passwordConfirmation.value &&
      passwordConfirmation.value !== password.value
    ) {
      setPasswordConfirmationError(true);
      setPasswordConfirmationErrorMessage("Passwords must match.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  return (
    <Grid container component={"main"} sx={{ height: "100vh" }}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between">
        <Stack
          sx={{
            justifyContent: "center",
            height: "100dvh",
            p: 2,
          }}
        >
          <MainCard variant="outlined">
            <Typography
              component="h1"
              variant="h4"
              align={"center"}
              sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <FormControl variant="outlined">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  error={passwordError}
                  name="password"
                  placeholder="••••••"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoFocus
                  required
                  fullWidth
                  label="Password"
                  color={passwordError ? "error" : "primary"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passwordErrorMessage && (
                  <FormHelperText id="password-error-text">
                    {passwordErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="password-confirmation">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  label="Confirm Password"
                  error={passwordConfirmationError}
                  name="password-confirmation"
                  placeholder="••••••"
                  type={showPassword ? "text" : "password"}
                  id="password-confirmation"
                  autoFocus
                  required
                  fullWidth
                  color={passwordConfirmationError ? "error" : "primary"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passwordConfirmationErrorMessage && (
                  <FormHelperText id="password-confirmation-error-text">
                    {passwordConfirmationErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={validateInputs}
                disabled={loading}
              >
                Reset Password
              </Button>
            </Box>
          </MainCard>
        </Stack>
      </SignInContainer>
    </Grid>
  );
}
