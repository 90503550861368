import { GridRowModel } from "@mui/x-data-grid";
import { useMutation, useQuery } from "react-query";

import {
  BusinessUnit,
  FileRow,
  FileUtilityType,
  SavedHeaderSchema,
  ListResponseData
} from "../types";
import {
  handleBasicFetch,
  handleBasicPost,
  handleMultiPartFormPost,
  handleList,
  handleError,
  handleDelete
} from "./handlers";
import queryClient from "./queryClient";
import snakeCaseKeys from 'snakecase-keys'

const methods = {
  // getFiles: async function getFiles(): Promise<File[]> {
  //   try {
  //     const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/files`);
  //     return await resp.json();
  //   } catch (error) {
  //     console.error(error);
  //     return [];
  //   }
  // },
  useFetchSavedHeaderSchemasPaginated: ({
    businessUnitId,
    fileUtilityType,
    query,
    page,
    perPage
  }: {
    businessUnitId?: number;
    fileUtilityType?: FileUtilityType;
    page?: number;
    perPage: number;
    query?: string;
  })  => {
    return useQuery<ListResponseData<SavedHeaderSchema>>({
      retry: 0,
      enabled: !!fileUtilityType && !!businessUnitId,
      queryKey: ["saved_header_schemas", fileUtilityType, page, perPage, query],
      queryFn: () =>
        handleList({
          page,
          perPage,
          query: query || "",
          baseUrl: 'saved_header_schemas',
          params: [
            { key: 'business_unit_id', value: businessUnitId },
            { key: 'file_utility_type', value: fileUtilityType }
          ]
        }),
      onError: handleError
    })
  },
  useFetchSavedHeaderSchemas: ({
    fileUtilityType,
    query,
    businessUnitId,
  }: {
    businessUnitId: number;
    fileUtilityType?: FileUtilityType;
    query?: string;
  }) => {
    return useQuery<SavedHeaderSchema[]>({
      enabled: !!fileUtilityType,
      queryKey: ["saved_header_schemas", fileUtilityType],
      queryFn: () =>
        handleBasicFetch({
          url: "saved_header_schemas/search",
          params: [
            { key: "business_unit_id", value: businessUnitId },
            { key: "query", value: query },
            { key: "file_utility_type", value: fileUtilityType },
          ],
        }),
    });
  },
  useFetchSavedHeaderSchema: (id?: number) => {
    return useQuery<SavedHeaderSchema>({
      enabled: !!id,
      queryKey: ["saved_header_schema", id?.toString()],
      queryFn: () =>
        handleBasicFetch({
          url: `saved_header_schemas/${id}`,
          params: [],
        }),
    });
  },
  useCreateSavedHeaderSchemas: ({
    businessUnitId,
    fileUtilityType,
    name,
    columnHeaders,
  }: {
    businessUnitId?: number;
    fileUtilityType: FileUtilityType;
    name: string;
    columnHeaders: { [key: number]: string };
  }) => {
    return useMutation<SavedHeaderSchema>({
      mutationFn: () =>
        handleBasicPost({
          url: "saved_header_schemas",
          input: snakeCaseKeys({
            businessUnitId,
            fileUtilityType,
            name,
            columnHeaders,
          }),
        }),
    });
  },
  useUpdateSavedHeaderSchemas: ({
    businessUnitId,
    savedHeaderSchemaId,
    name,
    columnHeaders,
  }: {
    businessUnitId?: number;
    savedHeaderSchemaId?: number;
    name: string;
    columnHeaders: { [key: number]: string };
  }) => {
    return useMutation<SavedHeaderSchema>({
      mutationFn: () =>
        handleBasicPost({
          url: `saved_header_schemas/${savedHeaderSchemaId}`,
          input: snakeCaseKeys({
            businessUnitId,
            savedHeaderSchemaId,
            name,
            columnHeaders,
          }),
        }),
    });
  },
  useDeleteSavedHeaderSchemas: () => {
    return useMutation({
      mutationFn: (id: number) =>
        handleDelete<SavedHeaderSchema>({
          baseUrl: `saved_header_schemas`,
          id
        }),
      onSuccess: async (data: SavedHeaderSchema) => {
        queryClient.invalidateQueries({ queryKey: ["saved_header_schemas"] });
        // queryClient.setQueryData(
        //   ["evaluations", evaluableType, evaluableId.toString()],
        //   (oldData: Evaluation[] | undefined) => {
        //     if (oldData) {
        //       return unionBy(data, oldData, "id");
        //     } else {
        //       return data;
        //     }
        //   }
        // );
      }
    })
  },
  useUploadValidFileData: () => {
    return useMutation({
      mutationFn: ({
        fileUtilityType,
        rows,
        businessUnit,
        month,
        year,
      }: {
        fileUtilityType: FileUtilityType;
        rows: readonly GridRowModel[];
        businessUnit?: BusinessUnit;
        month?: number;
        year?: number;
      }): Promise<{ status: string; data: FileRow[] }> => {
        const formData = new FormData();
        if (businessUnit) {
          formData.append("business_unit_id", businessUnit?.id?.toString());
        }
        if (month) {
          formData.append("month", month?.toString());
        }
        if (year) {
          formData.append("year", year?.toString());
        }
        formData.append("file_utility_type", fileUtilityType);
        formData.append("rows", JSON.stringify(rows));
        return handleMultiPartFormPost({
          url: "files/upload",
          formData,
        });
      },
    });
  },
};

export default methods;