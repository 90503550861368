import { Box, Step, StepButton, Stepper } from "@mui/material";

import { useFileUploaderContext } from "../context/fileUploader.context";
import { FileUploaderState } from "../types";

const steps = [
  "Select Month & Year",
  "Upload Spreadsheet",
  "Select Header Row",
  "Match Headers",
  "Validate Data",
  "Complete",
];

export default function UploadSpreadsheetStepper() {
  const { fileUploaderState, setFileUploaderState } = useFileUploaderContext();
  const activeStep = fileUploaderStateToIndex(fileUploaderState);

  const handleStep = (step: number) => () => {
    setFileUploaderState &&
      setFileUploaderState(indexToFileUploaderState(step));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

function fileUploaderStateToIndex(fileUploaderState?: FileUploaderState) {
  if (fileUploaderState === "initial_state") {
    return 0;
  } else if (fileUploaderState === "upload_screen") {
    return 1;
  } else if (fileUploaderState === "uploaded_valid_spreadsheet") {
    return 2;
  } else if (fileUploaderState === "selected_header_index") {
    return 3;
  } else if (fileUploaderState === "validate_data") {
    return 4;
  } else if (fileUploaderState === "complete") {
    return 5;
  }
  return 0;
}

function indexToFileUploaderState(step: number) {
  if (step === 0) {
    return "initial_state";
  } else if (step === 1) {
    return "upload_screen";
  } else if (step === 2) {
    return "uploaded_valid_spreadsheet";
  } else if (step === 3) {
    return "selected_header_index";
  } else if (step === 4) {
    return "validate_data";
  } else if(step === 5){
    return "complete";
  }
  return "initial_state";
}
