import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";

import { useFileUploaderContext } from "../context/fileUploader.context";
import { excelHeaderName } from "../utils";

const showNumRows = 5;

export default function SelectHeaderRow() {
  const {
    worksheetData,
    setFileUploaderState,
    headerRowIndex,
    setHeaderRowIndex,
  } = useFileUploaderContext();

  const [error, setError] = React.useState<string | undefined>();
  const initialRowIndex = 0;
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>(headerRowIndex !== undefined ? [headerRowIndex]: []);

  const columns: GridColDef[] = React.useMemo(
    () => setColumns({ worksheetData, initialRowIndex, setError }),
    [worksheetData, initialRowIndex, setError]
  );

  const rows = React.useMemo(
    () => setRows({ initialRowIndex, worksheetData, columns, setError }),
    [worksheetData, initialRowIndex, setError, columns]
  );

  if(error){
    return (
      <Box>
        <Typography color="error" variant="h6">Error: Issue reading selected sheet from the Excel file.</Typography>
      </Box>
    )
  }

  // const columns: GridColDef[] = setColumns({ worksheetData, initialRowIndex, setError });
  // const rows = setRows({ initialRowIndex, worksheetData, columns, setError })

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Select Header row</Typography>
        {headerRowIndex !== undefined && (
          <Button
            variant={"contained"}
            onClick={() =>
              setFileUploaderState &&
              setFileUploaderState("selected_header_index")
            }
          >
            Continue
          </Button>
        )}
      </Box>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          // rowHeight={38}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableMultipleRowSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            if (newRowSelectionModel[0] !== undefined) {
              setHeaderRowIndex &&
                setHeaderRowIndex(newRowSelectionModel[0] as number);
            } else {
              setHeaderRowIndex && setHeaderRowIndex(undefined);
            }
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </div>
    </Box>
  );
}

function setRows({
  initialRowIndex,
  worksheetData,
  columns,
  setError
}: {
  columns: GridColDef[];
  initialRowIndex: number;
  worksheetData: any;
  setError: (arg?: string) => void;
}) {
  try {
    const rows = [];
    for (let ri = 0; ri < worksheetData.length; ri++) {
      const row: { [key: string]: any } = {};
      row["id"] = ri;
      for (let ci = 0; ci < columns.length; ci++) {
        const key = columns[ci]["field"];
        const value = Object.values(worksheetData[initialRowIndex + ri])[ci];
        // row[key] = value ? value["v"] : undefined;
        row[key] = value;
      }
      rows.push(row);
    }
    return rows;
  } catch (error){
    // @ts-ignore
    setError(error && error.message)
    return [];
  }
}

function setColumns({
  initialRowIndex,
  worksheetData,
  setError
}: {
  initialRowIndex: number;
  worksheetData: any;
  setError: (arg?: string) => void;
}): GridColDef[] {
  try {
    let maxColumnLengthForVisibleRows = 0;
    for (let i = 0; i < showNumRows; i++) {
      const columnLength = Object.values(worksheetData[initialRowIndex + i]).length;
      if (columnLength && columnLength > maxColumnLengthForVisibleRows) {
        maxColumnLengthForVisibleRows = columnLength;
      }
    }

    const columns = [];
    for (let i = 0; i < maxColumnLengthForVisibleRows; i++) {
      columns.push({
        field: `column${i}`,
        headerName: excelHeaderName(i),
        sortable: false,
        hideable: false,
      });
    }
    return columns;
  } catch (error){
    // @ts-ignore
    setError(error && error.message)
    return [];
  }
}
