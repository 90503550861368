import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";

import { useFilterSearchContext } from "../context/filterSearch.context";

export default function FilterListData() {
  const {
    query,
    setQuery,
    toggleFilter,
    setToggleFilter,
    datePickerMonth,
    setDatePickerMonth,
    datePickerYear,
    setDatePickerYear,
  } = useFilterSearchContext();
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <TextField
          id="filled-search"
          label="Search"
          type="search"
          variant="filled"
          fullWidth
          value={query}
          onChange={(e) => {
            setQuery && setQuery(e.target.value);
          }}
        />
        <Box
          sx={{
            flexShrink: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            size="large"
            onClick={() => setToggleFilter && setToggleFilter(!toggleFilter)}
          >
            <FilterListIcon />
          </Button>
        </Box>
      </Box>
      {toggleFilter && (
        <Paper
          elevation={2}
          sx={{
            my: "1rem",
            p: "1rem",
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
          }}
        >
          <DatePicker
            label={"Month"}
            views={["month"]}
            value={datePickerMonth}
            onChange={(newValue) => {
              if (newValue) {
                setDatePickerMonth && setDatePickerMonth(newValue);
              } else {
                setDatePickerMonth && setDatePickerMonth(null);
              }
            }}
          />
          <DatePicker
            label={"Year"}
            views={["year"]}
            value={datePickerYear}
            onChange={(newValue) => {
              if (newValue) {
                setDatePickerYear && setDatePickerYear(newValue);
              } else {
                setDatePickerYear && setDatePickerYear(null);
              }
            }}
          />
        </Paper>
      )}
    </>
  );
}
