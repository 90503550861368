import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";

import SaleApi from "../api/sale.api";
import { useFilterSearchContext } from "../context/filterSearch.context";
import { useBusinessUnit } from '../context/businessUnit.context'
import { Sale} from "../types";
import { convertToCurrency } from "../utils";
import TablePaginationActions from "./TablePaginationActions";

export default function SaleTable() {
  const {
    page,
    datePickerMonth,
    datePickerYear,
    query,
    perPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useFilterSearchContext();

  const { businessUnit } = useBusinessUnit()

  const { data } = SaleApi.useList({
    query,
    page,
    perPage,
    month: datePickerMonth ? datePickerMonth.month() : undefined,
    year: datePickerYear ? datePickerYear.year() : undefined,
    businessUnitId: businessUnit?.id
  });

  const emptyRows =
    rowsPerPage && page && page > 0 && data && data.data
      ? Math.max(0, (1 + page) * rowsPerPage - data.meta.count)
      : 0;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Year</TableCell>
            <TableCell>NDC</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Unit Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.data.map((sale: Sale) => (
              <TableRow key={sale.id}>
                <TableCell>
                  {dayjs().month(sale.month).format("MMMM")}
                </TableCell>
                <TableCell>{sale.year}</TableCell>
                <TableCell>{sale.ndc}</TableCell>
                <TableCell>{sale.quantity}</TableCell>
                <TableCell>{convertToCurrency(sale.unitPrice)}</TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {data && data.meta && perPage && handleChangePage && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={5}
                count={data.meta.count}
                rowsPerPage={perPage}
                page={data.meta.current}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
