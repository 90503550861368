import { Grid, Typography } from "@mui/material";
import React from "react";

import UploadSpreadsheetFlow from "../components/UploadSpreadsheetFlow";
import FileUploaderProvider from "../context/fileUploader.context";

export default function Uploader() {
  return (
    <FileUploaderProvider>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">Uploader</Typography>
        </Grid>
        <Grid item xs={12}>
          <UploadSpreadsheetFlow />
        </Grid>
      </Grid>
    </FileUploaderProvider>
  );
}
